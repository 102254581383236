body {
  margin: auto;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.content{
  max-width: 1000px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
}
#stripes{
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-transform: skewY(-12deg);
  transform: skewY(-12deg);
  -webkit-transform-origin: 0;
  transform-origin: 0;
  background-color: #F5F7FA;
  position: absolute;
}
#stripes :first-child {
  width: 25%;
  left: -16.66666%;
  left: 0px;
  background-color: #1FB0A1;
  bottom: 0;
  height: 81px;
  position: absolute;
}
#stripes :nth-child(2){
  width: 33%;
  left: -16.66666%;
  left: calc(calc(calc(100% / 3) / 2) * -1);
  background-color: #67C9BE;
  bottom: 81px;
  height: 81px;
  position: absolute;
}
#stripes :nth-child(3){
  width: 33.33333%;
  width: calc(100% / 3);
  bottom: 0px;
  right: -16.66666%;
  right: calc(calc(calc(100% / 3) / 2) * -1);
  background-color: #67C9BE;
  position: absolute;
  height: 81px;
}
#stripes :nth-child(4){
  width: 25.33333%;
  right: -16.66666%;
  right: calc(calc(calc(100% / 3) / 2) * -1);
  background-color: #4CC2B6;
  position: absolute;
  height: 81px;
  bottom: 81px;
}

.content{
  position: relative;
}
.title-text{
  color: #2F353A;	
  font-family: "Open Sans";	
  font-size: 56px;
  font-weight: bold;
  margin-bottom: 25px;
}
.subtitle-text{
  color: #2F353A;	
  font-family: "Helvetica Neue";	
  font-size: 24px;	
  font-weight: 500;	
  line-height: 36px;
}
.header-bar{
  background-color: #F5F7FA;
  padding: 30px;
  display: flex;
  cursor: pointer;
  
}
.header-labs-text{
  margin-left: 10px;
  font-family: "Helvetica Neue";	
  font-size: 39px;	
  font-weight: 300;
  
}
.label-text{
  
  color: #000000;	
  font-family: "IBM Plex Mono";	
  font-weight: 300;
  font-size: 16px;
}
.input-chages{
  border-radius: 0px;
}
.full-form{
  width: 100%;
}
.about-box{
  box-shadow: 0 2px 20px 1px rgba(169,180,190,0.3);
}
.about-title{
  color: #47B7AB;	
  font-family: "Helvetica Neue";	
  font-size: 20px;	
  font-weight: 500;	
}
.about-body{
  color: #A9B4BE;	
  font-family: "Open Sans";	
  font-size: 16px;	
}
a{
  color: #47B7AB;
  text-decoration: none;
}
.custom-checkbox-container{
  margin-left: -15px !important;
}
.tozny-footer-box{
  height: 300px;
  background-color: #2F353A;
  padding-top: 100px;
  padding-left: 54px;
  padding-right: 54px;
  display: flex;
  flex-direction: column;
}
.inverse-logo{
  fill: #ffffff;
  margin-bottom: 30px;
}
.footer-row{
  display: flex;
  flex-direction: row;
}
.footer-column{
  margin-right: 30px;
}
.footer-column a{
  color: white;
}
.column-title{
  color: white;
  font-family: "Helvetica Neue";	
  font-size: 16px;	
  font-weight: 500;
  margin-bottom: 16px;
}
.column-entry{
  font-family: "Open Sans";	
  font-size: 16px;	
  line-height: 22px;
  color: white;
  margin-bottom: 5px;
}
.tozny-about-box{
  min-height: 300px;
  background-color: #47B7AB;
  padding-top: 100px;
  padding-left: 54px;
  padding-right: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.about-box-subtitle{
  color: #FFFFFF;	
  font-family: "Helvetica Neue";	
  font-size: 20px;	
  font-weight: 500;	
  
}
.about-box-title{
  margin-top: -10px;
  color: #FFFFFF;	
  font-family: "Open Sans";	
  font-size: 28px;	
  font-weight: 600;
}
.about-general{
  text-align: center;
  color: #EAEAEA;	font-family: "Open Sans";	font-size: 20px;

}